import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../common/button";

import ActiveIcon from "../../../assets/images/icons/iconAction.svg";
import clearFilter from "../../../assets/images/icons/Clear-Filter-Icon-White.svg";
import AddItem from "../../../assets/images/icons/addItem.svg";
import downArrow from "../../../assets/images/dropdownArrow.png";
import Search from "../../../assets/images/icons/SearchIcon.svg";
import unassign from "../../../assets/images/Unassign.png";
import AddDealer from "../../../assets/images/Disapproved.png";
import Headbar from "../../../common/headBar";
import Edit from "../../../assets/images/Dealer/EditIcon.svg";
import shorting from "../../../assets/images/icons/shorting.svg";
import Grid from "../../../common/grid";
import Input from "../../../common/input";
import DataTable from "react-data-table-component";
import Primary from "../../../assets/images/SetPrimary.png";
import Select from "../../../common/select";
import { RotateLoader } from "react-spinners";
import {
  archiveOrders,
  getOrders,
  markPaid,
  processOrders,
} from "../../../services/orderServices";
import Modal from "../../../common/model";
import Cross from "../../../assets/images/Cross.png";
import download from "../../../assets/images/download.png";
import view from "../../../assets/images/eye.png";
import edit from "../../../assets/images/edit-text.png";
import remove from "../../../assets/images/delete.png";
import mark from "../../../assets/images/pay.png";
import process from "../../../assets/images/return.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import PdfGenerator from "../../pdfViewer";
import DocMakeOrderContainer from "../../docMakeOrder";
import Card from "../../../common/card";
import InActiveButton from "../../../common/inActiveButton";
import SingleView from "../../../common/singleView";

function OrderList() {
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [orderId, SetOrderId] = useState("");
  const [timer, setTimer] = useState(3);
  const [orderList, setOrderList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isArchiveOpen, setIsArchiveOpen] = useState(false);
  const [markLoader, setMarkLoader] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [processOrderErrors, setProcessOrderErrors] = useState([]);
  const [errorList, SetErrorList] = useState([]);
  const [orderType, SetOrderType] = useState("");
  const [errorLine, SetErrorLine] = useState(
    "Order can not be process to the following reasons"
  );
  const [isDisapprovedOpen, setIsDisapprovedOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [primaryMessage, setPrimaryMessage] = useState("");
  const [secondaryMessage, setSecondaryMessage] = useState("");
  const dropdownRef = useRef(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { orderValue } = useParams();
  const closeDisapproved = () => {
    setIsDisapprovedOpen(false);
  };

  const openDisapproved = () => {
    setIsDisapprovedOpen(true);
  };

  const openArchive = (id) => {
    setMessage("Would you like to Archive it?");
    SetOrderId(id);
    setIsArchiveOpen(true);
  };

  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  const handleSelectChange = (name, selectedValue) => {
    formik.setFieldValue(name, selectedValue);
  };
  const handleFilterIconClick = () => {
    formik.resetForm();
    console.log(formik.values);
    formik.setFieldValue('orderId', '')
    navigate('/orderList')
    // getOrderList();
  };
  useEffect(() => {
    let intervalId;
    if (isModalOpen1 && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0) {
      closeArchive();
      getOrderList();
      closeModal1();
    }
    return () => clearInterval(intervalId);
  }, [isModalOpen1, timer]);

  const openModal1 = async () => {
    try {
      console.log(message);
      setMarkLoader(true);

      let res;
      if (message === "Would you like to Archive it?") {
        res = await archiveOrders(orderId);
      } else {
        res = await markPaid(orderId);
      }

      if (res.code === 401) {
        SetErrorLine("Order cannot be processed for the following reasons");
        SetErrorList(res.message);
        closeArchive();
        setIsModalOpen(true);
      } else if (res.code === 200) {
        if (message === "Would you like to Archive it?") {
          SetOrderType("Archive");
          setPrimaryMessage("Archive Order Successfully");
          setSecondaryMessage("You have successfully archived the order");
        } else {
          SetOrderType("Paid");
          setPrimaryMessage("Order Successfully Paid.");
          setSecondaryMessage("You have successfully marked the order as paid");
        }
        setTimer(3);
        setIsModalOpen1(true);
      } else {
        console.error("Unknown response code:", res.code);
      }
    } catch (error) {
      console.error(
        `Error while ${message === "Would you like to Archive it?"
          ? "archiving"
          : "marking as paid"
        } order:`,
        error
      );
    } finally {
      setMarkLoader(false);
    }
  };

  const closeArchive = () => {
    setIsArchiveOpen(false);
  };

  const validationSchema = Yup.object().shape({});

  const initialValues = {
    orderId: "",
    venderOrder: "",
    dealerName: "",
    resellerName: "",
    customerName: "",
    servicerName: "",
    status: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      getOrderList(values);

      console.log(values);
    },
  });

  const openModal = (id) => {
    setData(id);
    processOrders(id).then((res) => {
      SetErrorLine("Order can not be process to the following reasons");
      SetOrderType("Process");
      setSelectedAction(null);
      setProcessOrderErrors(res.result);
      SetErrorList(res.result);
      // console.log(res.result);
    });

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [loading, setLoading] = useState(false);

  const status = [
    { label: "Active", value: "Active" },
    { label: "Pending", value: "Pending" },
  ];

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSelectedAction(null);
    }
  };

  useEffect(() => {
    initialValues.orderId = orderValue
    getOrderList(orderValue == undefined ? {} : { orderId: orderValue });

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const getOrderList = async (data = {}) => {
    closeDisapproved(false);
    setLoading(true);
    const result = await getOrders(data);
    console.log(result.result);
    setOrderList(result.result);
    setLoading(false);
  };
  const calculateDropdownPosition = (index) => {
    const isCloseToBottom = 1 - index <= 1;
    return isCloseToBottom ? "bottom-[1rem]" : "bottom-[1rem]";
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
  };

  const CustomNoDataComponent = () => (
    <Card className="text-center my-5">
      <p>No records found.</p>
    </Card>
  );

  const markasPaid = async (row) => {
    setMessage(
      `Would you prefer to make the full payment $${row.orderAmount - row.paidAmount
      } ?`
    );
    SetOrderId(row._id);
    setIsArchiveOpen(true);
  };

  const formatOrderValue = (orderValue) => {
    if (Math.abs(orderValue) >= 1e6) {
      return (orderValue / 1e6).toFixed(2) + "M";
    } else {
      return orderValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };

  console.log("setSelectedAction ===============>>", selectedAction);

  const columns = [
    {
      name: (
        <div>
          Order
          <br />
          ID
        </div>
      ),
      selector: (row) => row?.unique_key,
      sortable: true,
      minWidth: "auto",
      maxWidth: "123px",
    },
    {
      name: "Dealer P.O #",
      selector: (row) => row.venderOrder,
      sortable: true,
      minWidth: "180px",
    },
    {
      name: "Dealer Name",
      selector: (row) => row.dealerName.name,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) => row.customerName.username,
      sortable: true,
    },
    {
      name: "# Contracts",
      selector: (row) =>
        row?.noOfProducts == null ? 0 : row.noOfProducts.toLocaleString(2),
      sortable: true,
      minWidth: "100px",
    },
    {
      name: (
        <div>
          Order <br /> Value
        </div>
      ),
      selector: (row) =>
        `$${row?.orderAmount === undefined
          ? parseInt(0).toLocaleString(2)
          : formatOrderValue(row?.orderAmount ?? parseInt(0))
        }`,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Status",
      cell: (row) => (
        <div className="flex border py-2 rounded-lg w-[80%] mx-auto">
          <div
            className={` ${row.status === "Pending" ? "bg-[#8B33D1]" : "bg-[#6BD133]"
              }  h-3 w-3 rounded-full self-center  mr-2 ml-[8px]`}
          ></div>
          <p className="self-center"> {row.status} </p>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Action",
      minWidth: "auto",
      maxWidth: "80px",
      cell: (row, index) => {
        return (
          <div className="relative">
            <div
              onClick={() =>
                setSelectedAction(
                  selectedAction === row.unique_key ? null : row.unique_key
                )
              }
            >
              <img
                src={ActiveIcon}
                className="cursor-pointer w-[35px]"
                alt="Active Icon"
              />
            </div>
            {selectedAction === row.unique_key && (
              <SingleView
                ref={dropdownRef}
                onClick={(e) => {
                  // e.stopPropagation(); // Prevent event from reaching the document listener
                  setSelectedAction(null);
                }}
                // onClick={() => setSelectedAction(null)}
                className={`absolute z-[2] w-[140px] drop-shadow-5xl -right-3 mt-2 py-2 border rounded-lg shadow-md bottom-[1rem]`}
              >
                {/* <img src={downArrow} className={`absolute  object-contain left-1/2 w-[12px] ${index%10 === 9 ? 'bottom-[-5px] rotate-180' : 'top-[-5px]'} `} alt='up arror'/> */}
                {row.status == "Pending" ? (
                  <>
                    <div
                      className="text-left py-1 px-2 flex border-b cursor-pointer"
                      onClick={() => navigate(`/editOrder/${row._id}`)}
                    >
                      <div
                        style={{
                          maskImage: `url(${edit})`,
                          WebkitMaskImage: `url(${edit})`,
                          maskRepeat: "no-repeat",
                          WebkitMaskRepeat: "no-repeat",
                          maskPosition: "center",
                          WebkitMaskPosition: "center",
                          maskSize: "contain",
                          WebkitMaskSize: "contain",
                        }}
                        className="self-center singleViews mr-2 h-4 w-4 "
                      /> Edit
                    </div>
                    <div
                      className="text-left py-1 px-2 flex border-b cursor-pointer"
                      onClick={() => openModal(row._id)}
                    >
                      <div
                        style={{
                          maskImage: `url(${process})`,
                          WebkitMaskImage: `url(${process})`,
                          maskRepeat: "no-repeat",
                          WebkitMaskRepeat: "no-repeat",
                          maskPosition: "center",
                          WebkitMaskPosition: "center",
                          maskSize: "contain",
                          WebkitMaskSize: "contain",
                        }}
                        className="self-center singleViews mr-2 h-4 w-4 "
                      /> Process
                      Order
                    </div>
                    {row.flag && (
                      <div
                        className="text-center py-1 px-2 border-b  flex cursor-pointer"
                        onClick={() => markasPaid(row)}
                      >
                        <div
                          style={{
                            maskImage: `url(${mark})`,
                            WebkitMaskImage: `url(${mark})`,
                            maskRepeat: "no-repeat",
                            WebkitMaskRepeat: "no-repeat",
                            maskPosition: "center",
                            WebkitMaskPosition: "center",
                            maskSize: "contain",
                            WebkitMaskSize: "contain",
                          }}
                          className="self-center singleViews mr-2 h-4 w-4 "
                        /> Mark as Paid
                      </div>
                    )}

                    <div className="border-b">
                      <PdfGenerator
                        data={row._id}
                        setLoading={setLoading}
                        onClick={() => setSelectedAction(null)}
                      />
                    </div>
                    <div
                      className="text-left py-1 px-2 flex cursor-pointer hover:font-semibold"
                      onClick={() => openArchive(row._id)}
                    >
                      <div
                        style={{
                          maskImage: `url(${remove})`,
                          WebkitMaskImage: `url(${remove})`,
                          maskRepeat: "no-repeat",
                          WebkitMaskRepeat: "no-repeat",
                          maskPosition: "center",
                          WebkitMaskPosition: "center",
                          maskSize: "contain",
                          WebkitMaskSize: "contain",
                        }}
                        className="self-center singleViews mr-2 h-4 w-4 "
                      /> Archive
                    </div>
                  </>
                ) : (
                  <>
                    <div onClick={() => localStorage.removeItem("orderMenu")}>
                      <Link
                        to={`/orderDetails/${row._id}`}
                        className="text-left py-1 px-2 cursor-pointer border-b w-full flex justify-start"
                      >
                        <div
                          style={{
                            maskImage: `url(${view})`,
                            WebkitMaskImage: `url(${view})`,
                            maskRepeat: "no-repeat",
                            WebkitMaskRepeat: "no-repeat",
                            maskPosition: "center",
                            WebkitMaskPosition: "center",
                            maskSize: "contain",
                            WebkitMaskSize: "contain",
                          }}
                          className="self-center singleViews mr-2 h-4 w-4 "
                        /> View
                      </Link>
                    </div>
                    <div className="">
                      <PdfGenerator data={row._id} setLoading={setLoading} />
                    </div>
                  </>
                )}
              </SingleView>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="mb-8 ml-3">
        <Headbar />

        <div className="flex mt-2">
          <div className="pl-3">
            <p className="font-bold text-[36px] leading-9 mb-[3px]">Order</p>
            <ul className="flex self-center">
              <li className="text-sm  font-Regular">
                <Link to={"/"}>Home </Link> /{" "}
              </li>
              <li className="text-sm font-semibold ml-1 pt-[1px]">
                {" "}
                Order List{" "}
              </li>
            </ul>
          </div>
        </div>

        <InActiveButton className="flex self-center mb-4 rounded-xl ml-auto border-[1px] border-Light-Grey">
          {" "}
          <Link to={"/addOrder"} className="flex">
            {" "}
            <div
              style={{
                maskImage: `url(${AddItem})`,
                WebkitMaskImage: `url(${AddItem})`,
                maskRepeat: "no-repeat",
                WebkitMaskRepeat: "no-repeat",
                maskPosition: "center",
                WebkitMaskPosition: "center",
                maskSize: "contain",
                WebkitMaskSize: "contain",
              }}
              className="self-center pr-1 py-1 h-4 w-4 "
            />
            {/* <img src={AddItem} className="self-center" alt="AddItem" />{" "} */}
            <span className=" ml-2 text-[14px] font-Regular ">
              {" "}
              Add New Order{" "}
            </span>{" "}
          </Link>
        </InActiveButton>

        <Card className="bg-white mt-6 border-[1px] border-Light-Grey rounded-xl">
          <Grid className="!p-[26px] !pt-[14px] !pb-0">
            <div className="col-span-3 self-center">
              <p className="text-xl font-semibold">Order List</p>
            </div>
            <div className="col-span-9">
              <div className="bg-grayf9 rounded-[30px] p-3 border-[1px] border-Light-Grey">
                <form onSubmit={formik.handleSubmit}>
                  <Grid className="!grid-cols-9">
                    <div className="col-span-2 self-center">
                      <Input
                        name="Name"
                        type="text"
                        className="!text-[14px] !bg-White-Smoke"
                        className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                        label=""
                        placeholder="Order ID"
                        {...formik.getFieldProps("orderId")}
                      />
                    </div>
                    <div className="col-span-2 self-center">
                      <Input
                        name="orderNo"
                        type="text"
                        className="!text-[14px] !bg-White-Smoke"
                        className1="!text-[13px] !pt-1 placeholder-opacity-50 !pb-1 placeholder-Black-Russian !bg-[white]"
                        label=""
                        placeholder="Dealer P.O # "
                        {...formik.getFieldProps("venderOrder")}
                      />
                    </div>
                    <div className="col-span-2 self-center">
                      <Select
                        label=""
                        OptionName="Status"
                        options={status}
                        color="text-Black-Russian opacity-50"
                        className1="!pt-1 !pb-1 !text-[13px] !bg-[white]"
                        className="!text-[14px] !bg-White-Smoke"
                        selectedValue={selectedProduct}
                        onChange={handleSelectChange}
                        name="status"
                        value={formik.values.status}
                      />
                    </div>

                    <div className="col-span-3 self-center flex">
                      <Button type="submit" className="!p-2">
                        <img
                          src={Search}
                          className="cursor-pointer "
                          alt="Search"
                        />
                      </Button>

                      <InActiveButton
                        type="submit"
                        className='ml-1'
                        onClick={() => {
                          handleFilterIconClick();
                        }}
                      >
                        <div
                          style={{
                            maskImage: `url(${clearFilter})`,
                            WebkitMaskImage: `url(${clearFilter})`,
                            maskRepeat: "no-repeat",
                            WebkitMaskRepeat: "no-repeat",
                            maskPosition: "center",
                            WebkitMaskPosition: "center",
                            maskSize: "contain",
                            WebkitMaskSize: "contain",
                          }}
                          className="self-center px-1  py-1 h-4 w-4 cursor-pointer mx-auto"
                        />
                      </InActiveButton>
                      <Button
                        type="button"
                        className="ml-2 !text-sm"
                        onClick={() => openDisapproved()}
                      >
                        Advance Search
                      </Button>
                    </div>
                  </Grid>
                </form>
              </div>
            </div>
          </Grid>
          <div className="mb-5 relative">
            {loading ? (
              <div className=" h-[400px] w-full flex py-5">
                <div className="self-center mx-auto">
                  <RotateLoader color="#333" />
                </div>
              </div>
            ) : (
              <DataTable
                draggableColumns={false}
                columns={columns}
                data={orderList}
                highlightOnHover
                sortIcon={
                  <>
                    {" "}
                    <div
                      style={{
                        maskImage: `url(${shorting})`,
                        WebkitMaskImage: `url(${shorting})`,
                        maskRepeat: "no-repeat",
                        WebkitMaskRepeat: "no-repeat",
                        maskPosition: "center",
                        WebkitMaskPosition: "center",
                        maskSize: "contain",
                        WebkitMaskSize: "contain",
                      }}
                      className="ml-2 tabless"
                    />
                    {/* <img src={shorting} className="ml-2" alt="shorting" />{" "} */}
                  </>
                }
                pagination
                paginationPerPage={10}
                noDataComponent={<CustomNoDataComponent />}
                paginationComponentOptions={paginationOptions}
                paginationRowsPerPageOptions={[10, 20, 50, 100]}
              />
            )}
          </div>
        </Card>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {orderType == "Process" ? (
          <Button
            onClick={() => {
              navigate(`/editOrder/${data}`);
            }}
            className="absolute left-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
          >
            <img
              src={Edit}
              className="w-full h-full text-black rounded-full p-0"
            />
          </Button>
        ) : null}
        <Button
          onClick={closeModal}
          className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
        >
          <img
            src={Cross}
            className="w-full h-full text-black rounded-full p-0"
          />
        </Button>
        <div className="text-center py-3">
          <img src={AddDealer} alt="email Image" className="mx-auto" />

          <p className="text-3xl mb-0 mt-4 font-bold">
            <span className="">Error</span>{" "}
          </p>

          <p className="text-base font-medium mt-2">
            {errorLine} : <br />
            <span>{errorList} . </span>
          </p>
        </div>
      </Modal>

      <Modal isOpen={isArchiveOpen} onClose={closeArchive}>
        {markLoader ? (
          <>
            <div className=" h-[400px] w-full flex py-5">
              <div className="self-center mx-auto">
                <RotateLoader color="#333" />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-center py-3">
              <img src={unassign} alt="email Image" className="mx-auto my-4" />
              <p className="text-3xl mb-0 mt-2 font-[800] px-10">
                {message}
              </p>
              <Grid className="!grid-cols-4 my-5 ">
                <div className="col-span-1"></div>
                <Button onClick={() => openModal1()}>Yes</Button>
                <InActiveButton
                  className="border w-full !text-sm !font-Regular"
                  onClick={() => closeArchive()}
                >
                  No
                </InActiveButton>
                <div className="col-span-1"></div>
              </Grid>
            </div>
          </>
        )}
      </Modal>

      <Modal isOpen={isModalOpen1} onClose={closeModal1}>
        <div className="text-center py-3">
          <img src={Primary} alt="email Image" className="mx-auto my-4" />
          <p className="text-3xl mb-0 mt-2 font-[800]">
            {primaryMessage}
          </p>
          <p className="text-base font-medium mt-2">
            {secondaryMessage}
          </p>
          <p className="text-base font-medium mt-2">
            Redirecting you on Order List Page {timer} seconds.
          </p>
        </div>
      </Modal>

      <Modal isOpen={isDisapprovedOpen} onClose={closeDisapproved}>
        <Button
          onClick={closeDisapproved}
          className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
        >
          <img
            src={Cross}
            className="w-full h-full text-black rounded-full p-0"
          />
        </Button>
        <form onSubmit={formik.handleSubmit}>
          <div className="py-3">
            <p className="text-center text-3xl font-semibold ">
              Advance Search
            </p>
            <Grid className="mt-5 px-6">
              <div className="col-span-6">
                <Input
                  type="text"
                  id="orderId"
                  className="!bg-white"
                  label="Order ID"
                  placeholder=""
                  {...formik.getFieldProps("orderId")}
                />
              </div>
              <div className="col-span-6">
                <Input
                  type="text"
                  id="venderOrder"
                  className="!bg-white"
                  label="Dealer P.O. No."
                  placeholder=""
                  {...formik.getFieldProps("venderOrder")}
                />
              </div>
              {/* <div className="col-span-6">
                  <Input
                    type="text"
                    id="serialNo"
                    className="!bg-white"
                    label="Serial No."
                    placeholder=""
                    {...formik.getFieldProps("serialNo")}
                  />
                </div> */}
              <div className="col-span-6">
                <Input
                  type="text"
                  id="dealerName"
                  className="!bg-white"
                  label="Dealer Name"
                  placeholder=""
                  {...formik.getFieldProps("dealerName")}
                />
              </div>
              <div className="col-span-6">
                <Input
                  type="text"
                  id="resellerName"
                  className="!bg-white"
                  label="Reseller Name"
                  placeholder=""
                  {...formik.getFieldProps("resellerName")}
                />
              </div>
              <div className="col-span-6">
                <Input
                  type="text"
                  id="customerName"
                  className="!bg-white"
                  label="Customer Name"
                  placeholder=""
                  {...formik.getFieldProps("customerName")}
                />
              </div>
              <div className="col-span-6">
                <Input
                  type="text"
                  id="servicerName"
                  className="!bg-white"
                  label="Servicer Name"
                  placeholder=""
                  {...formik.getFieldProps("servicerName")}
                />
              </div>
              <div className="col-span-6">
                <Select
                  id="status"
                  label="Status"
                  name="status"
                  options={status}
                  className="!bg-white"
                  placeholder=""
                  value={formik.values.status}
                  onChange={handleSelectChange}
                />
              </div>
              <div className="col-span-12">
                <Button type="submit" className={"w-full"}>
                  Search
                </Button>
              </div>
            </Grid>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default OrderList;
