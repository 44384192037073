import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const xlexfile = (data) => {
    const today = new Date();
    const todayFormatted = `${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${today
            .getDate()
            .toString()
            .padStart(2, '0')}/${today.getFullYear()}`;

    // Filter data to include only claim_id, approved_date, and claim_amount
    const filteredData = data.map(({ unique_key, approveDate, totalAmount, status, claimStatus }) => {
        console.log({ status, approveDate, claimStatus }, '--- Debugging values');

        // Default formatted date
        let formattedDate = '';
        console.log(status, '---------status')

        if (status === 'unpaid') {
            // If unpaid, use today's date
            formattedDate = todayFormatted;
        } else if (!approveDate) {
            // If approveDate is missing, use claimStatus[0].date
            const claimDate = claimStatus?.[0]?.date;
            if (claimDate) {
                formattedDate = new Date(claimDate).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                });
            } else {
                formattedDate = 'N/A'; // Fallback if no date is available
            }
        } else {
            // Use approveDate
            formattedDate = new Date(approveDate).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
            });
        }

        return {
            'Claim ID': unique_key,
            'Approved Date': formattedDate,
            'Claim Amount($)': totalAmount,
        };
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Claims');

    const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
    });

    const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Use the first claim_id in data for the filename
    saveAs(blob, `Claims_Report.xlsx`);
};

export default xlexfile;